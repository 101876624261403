import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.jjdRed,
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '40vh',
    paddingBottom: 36,
    [QUERIES.desktop]: {
      minHeight: '64vh'
    }
  },
  content: {
    maxWidth: 600,
    width: '100%',
    margin: '0 auto'
  },
  h1: {
    color: COLORS.white,
    textAlign: 'center',
    margin: 0,
    fontSize: 28,
    lineHeight: '32px',
    position: 'relative',
    [QUERIES.desktop]: {
      fontSize: 48,
      lineHeight: '64px'
    }
  },
  p: {
    color: COLORS.jjdGreyish,
    opacity: 0.4,
    textAlign: 'center',
    fontWeight: '700'
  },
  openingQuoteMark: {
    left: -36
  },
  quoteMarks: {
    fontFamily: 'cursive',
    color: COLORS.jjdGreyish,
    padding: '0 8px',
    fontSize: 73,
    position: 'absolute',
    opacity: 0.34,
    [QUERIES.mobile]: {
      display: 'none'
    }
  }
});

export default styles;
