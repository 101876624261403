import React from "react";
import EveryReview from "../containers/reviews/EveryReview/EveryReview";
import Layout from "../containers/gatsby/layout";
import ReviewsHero from "../containers/heroes/ReviewsHero/ReviewsHero";
import SEO from "../containers/gatsby/seo";

const ReviewsPage = (props) => (
  <Layout location={props.location}>
    <SEO title={"Best man and van prices 2019"} />
    <ReviewsHero />
    <EveryReview />
  </Layout>
);

export default ReviewsPage;
