import React from "react";
import styles from "./EveryReview.styles";
import TrustBoxContainer from "./TrustBoxContainer";
import { css } from "aphrodite";
import REVIEWS from "../../../library/reviews";
import STYLES from "../../../styles/shared";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Star from "@material-ui/icons/StarRounded";
import SecondaryButton from "../../../components/buttons/SecondaryButton/SecondaryButton";

const EveryReview = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      {/* <TrustBoxContainer /> */}
      {REVIEWS.map((review) => {
        return (
          <div
            className={css(styles.reviewContainer, STYLES.fullRow)}
            key={review.id}
          >
            <div className={css(styles.namesAndPlatform, STYLES.column)}>
              <div className={css(STYLES.row)}>
                <Avatar className={css(styles.avatar)}>
                  {review.name.charAt(0)}
                </Avatar>
                <h6 className={css(styles.name)}>{review.name}</h6>
              </div>
              <div style={{ height: 16 }} />
              <SecondaryButton
                target={"_blank"}
                href={review.link}
                title={review.platform}
              />
            </div>
            <div className={css(styles.reviewContent, STYLES.column)}>
              <div className={css(styles.starsContainer)}>
                {Array.apply(null, Array(review.stars)).map((star, i) => {
                  return <Star className={css(styles.starIcon)} key={i} />;
                })}
              </div>
              <h3 className={css(styles.reviewTitle)}>{review.title}</h3>
              <p className={css(styles.reviewBody)}>{review.body}</p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default EveryReview;
