import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.white
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    padding: '32px 0'
  },
  reviewContainer: {
    flexWrap: 'wrap',
    marginBottom: 32,
    [QUERIES.desktop]: {
      marginTop: 32
    }
  },
  avatar: {
    backgroundColor: COLORS.jjdRed,
    fontWeight: 'bold'
  },
  namesAndPlatform: {
    width: 240,
    padding: 16,
    [QUERIES.desktop]: {
      padding: 32
    },
    [QUERIES.mobile]: {
      width: '100%'
    }
  },
  reviewContent: {
    flex: 1,
    padding: 16,
    [QUERIES.desktop]: {
      padding: 32
    },
  },
  reviewBody: {
    margin: 0,
    fontSize: 16,
    color: COLORS.jjdGreyish
  },
  starsContainer: {
    paddingBottom: 8
  },
  starIcon: {
    background: '#54b77a',
    color: COLORS.white,
    marginLeft: 4,
  },
  reviewTitle: {
    paddingBottom: 8,
    fontWeight: 600
  },
  readReviewButton: {
    textTransform: 'none',
    marginTop: 24
  },
  name: {
    fontSize: 16,
    flex: 1,
    margin: 0,
    lineHeight: '40px',
    paddingLeft: 16,
    color: COLORS.offBlack
  }
});

export default styles;
