import React from 'react';
import {css} from 'aphrodite';
import styles from './ReviewsHero.styles';

const ReviewsHero = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <h1 className={css(styles.h1)}>
        <span className={css(styles.quoteMarks, styles.openingQuoteMark)}>“</span>
        By far the easiest way to book a man and van.
        <span className={css(styles.quoteMarks)}>”</span>
      </h1>
      <p className={css(styles.p)}>
        The driver was brilliant and the price was good.
      </p>
    </div>
  </div>
);

export default ReviewsHero